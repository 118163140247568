import { VStack, Container, Accordion, AccordionItem, AccordionButton, AccordionPanel, SimpleGrid, Box, HStack, Text, Spacer, Image } from '@chakra-ui/react';

import { usePrefsStore } from '../states/usePrefsStore';
import { qtyInCart, isInCart } from '../states/cartActions';

import { useStore } from '../states/useStore';
import Product from './Product';
import { useMediaQuery } from 'react-responsive';
import { breakpoint, listColumnsLargeScreen } from '../configs/settings';

const Products = ({ products }) => {
  const cart = useStore(state => state.cart);
  const checkIfInCart = pid => isInCart(cart, pid);
  const qtyCalculator = pid => qtyInCart(cart, pid);
  const isSmall = useMediaQuery({ maxWidth: breakpoint - 1 });

  if (isSmall) {
    return (
      <VStack w='full' spacing='4' px='4'>
        {products.map((prod, idx) =>
          <Product
            key={idx}
            selected={checkIfInCart(prod.productId)}
            qty={qtyCalculator(prod.productId)}
            product={prod} />
        )}
      </VStack>);
  }
  return (
    <SimpleGrid w='full' columns={listColumnsLargeScreen} columnGap={3} rowGap={2}>
      {products.map((prod, idx) =>
        <Product
          key={idx}
          selected={checkIfInCart(prod.productId)}
          qty={qtyCalculator(prod.productId)}
          product={prod} />
      )}
    </SimpleGrid>
  );
};

const CatStyles = {
  borderRadius: 'sm',
  textSize: '0.85rem',
  boxHeight: '40px'
};

const Category = ({ category }) => {
  const { key, name } = category;

  const imageSrc = require(`../assets/${key}.png`);

  return (
    <HStack
      w='full'
      pl='4'
      fontFamily='Montserrat'
      color='brand.100'
      bgGradient='linear(to-r, black, gray)'
      borderRadius={CatStyles.borderRadius}
    >
      <Text textAlign='left' fontSize={CatStyles.textSize}>{name}</Text>
      <Spacer />
      <Box h={CatStyles.boxHeight} overflowY='hidden'>
        <Image h={CatStyles.boxHeight} w='auto' src={imageSrc} />
      </Box>
    </HStack>);
};

const ListMenu = ({ categories }) => {
  return (
    <Accordion w='full' allowToggle>
      {Object.keys(categories).map(key => {
        const cat = categories[key];
        return (
          <AccordionItem key={key}>
            <AccordionButton>
              <Category catKey={key} category={cat} />
            </AccordionButton>
            <AccordionPanel>
              <Products products={cat.ps} />
            </AccordionPanel>
          </AccordionItem>);
      })
      }
    </Accordion>
  );
};

const ListStyle = { padding: '2' };

const List = () => {
  const categories = usePrefsStore(state => state.catalog.categories);
  // const imageSrc = require(`../assets/holidays/melbourne_cup.jpg`)

  return (
    <Container w='100%' p='1' maxW='90ch' mt='2' >
      <VStack w='full' spacing={3} p={ListStyle.padding} pt='2'>
        <Box w='full' px='4'>
          <VStack bg='black' p='2' borderRadius='5'>
            <Box textAlign='center' color='yellow'>MON - SAT 7AM-3:30PM</Box>
          </VStack>
        </Box>
        <ListMenu categories={categories} />
      </VStack>
    </Container>
  );
};
export default List;
