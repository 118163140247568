import { useEffect } from 'react';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import QRCode from 'qrcode.react';

import { calTotal, cartReset, itemNoText } from '../states/cartActions';
import { usePrefsStore } from '../states/usePrefsStore';

import { format } from 'date-fns';
import { Box, Button, Container, Flex, HStack, Spacer } from '@chakra-ui/react';
import { optionArrayToText } from '../common/utils';
import { orderById } from '../states/ordersActions';
import { useOrderStatus } from '../states/useRemoteStates';

const timestampToLocalDate = ts => {
  // return format(ts, 'mm/dd/yyyy hh:mm')
  return ts ? format(ts, 'dd-MM-yyyy hh:mm') : '';
};

const toText = selected =>
  Object.keys(selected).reduce(
    (acc, type) => acc + `<div><span>${type}:</span>${optionArrayToText(selected[type])}</div>`,
    ''
  );

const SelectedToText = ({ selected }) => {
  return (
    <Box
      sx={{
        '> div > span': {
          mr: '1',
          color: 'red.500',
          fontSize: 'sm',
          fontWeight: '500'
        }
      }}
      isTruncated
      dangerouslySetInnerHTML={{ __html: toText(selected) }} />
  );
};

const ItemOptions = ({ item }) => {
  return (
    <Box my='2'>
      <SelectedToText selected={item.selectedOptions} />
    </Box>
  );
};

const ItemExtras = ({ item }) => {
  // const noExtra = { key: 'NONE', name: 'No extra...' }
  const { name, price } = item.selectedExtra;
  const qty = item.qty; // qty of extra === qty of item
  return (
    <Flex my='3'>
      <Box textStyle='extraTitle'>{name}</Box>
      <Spacer />
      <Box textStyle='itemTitle'>${price}</Box>
      <Box mx='1'>x</Box>
      <Box textStyle='itemTitle'>{qty}</Box>
    </Flex>
  );
};

const Item = ({ item }) => {
  const isAnyOptions = item => item.selectedOptions;
  const isAnyExtra = item => item.selectedExtra;
  return (
    <Box
      m='3' p='3'
      border='1px'
      borderRadius='md'
      bg='gray.50'
      id={item.itemId}
    >
      <Flex>
        <Box textStyle='itemTitle'>{item.name}</Box>
        <Spacer />
        <Box textStyle='itemTitle'>${item.price}</Box>
        <Box mx='1'>x</Box>
        <Box textStyle='itemTitle'>{item.qty}</Box>
      </Flex>
      {isAnyExtra(item) && <ItemExtras item={item} />}
      {isAnyOptions(item) && <ItemOptions item={item} />}
    </Box>
  );
};

const Success = () => {
  const navigate = useNavigate();

  // params 'orderId':  /:orderId
  const match = useMatch('success/:orderId');
  const orderId = match.params.orderId;
  // use this query to keep status update
  const { data: { status }, refetch } = useOrderStatus(orderId);
  const order = orderById(orderId);

  // searchParams 'isCheckout': /:orderId?isCheckout=true
  const [searchParams] = useSearchParams();

  const statusNames = usePrefsStore(state => state.statusNames);
  const statusColors = usePrefsStore(state => state.statusColors);

  const {
    orderNumber,
    tsPickup,
    customerName,
    customerPhone,
    locAddress,
    cart
  } = order;


  useEffect(() => {
    if (searchParams.get('isCanceled')) {
      console.log('going to cart');
      navigate('/cart');
    }

    // reset cart if success component loaded from checkout process
    // otherwise, it just came from order history component
    if (searchParams.get('isCheckout')) {
      cartReset();
    }
  }, [searchParams, status, navigate]);

  if (!order) {
    return <Container>'The order not found!'</Container>;
  }

  return (
    <Container>
      <Flex w='full' my='3' px='6' direction='column'
        border='1px solid black'
        borderRadius='md'
        boxShadow='md'
        bgGradient='linear(to-t, brand.100, white)'
      >
        <Flex m='2' w='full' px='1' direction='column' alignItems='center'>
          <Box m='2' fontSize='lg' fontWeight='600'>DIGITAL RECEIPT</Box>
          <QRCode value={JSON.stringify(`${process.env.REACT_APP_TRACKING_URL}/#/success/${orderId}`)} />
          <Box fontSize='md' fontWeight='400'>{orderNumber}</Box>
        </Flex>
        <HStack
          w='full' my='2' fontSize='md' fontWeight='600'
          alignItems='center' justifyContent='space-between'
        >
          <Box>{`#${orderNumber.substr(orderNumber.length - 4, 4)}`}</Box>
          <Button variant='solid' onClick={refetch}
            color={`${statusColors[status] || 'black'}`} >
            {statusNames[status]}
          </Button>
        </HStack>
        <Flex my='2'>
          <Box textStyle='sectionTitle' mr='2'>Pickup time:</Box>
          <Box textStyle='normalTextBlue'>{timestampToLocalDate(tsPickup)}</Box>
        </Flex>
        <Box my='2'>
          <Box textStyle='sectionTitle'>Pickup point:</Box>
          <Flex direction='column'>
            <Box textStyle='normalTextBlue'>{locAddress}</Box>
          </Flex>
        </Box>
        <Box my='2'>
          <Flex justifyContent='flex-start'>
            <Box textStyle='sectionTitle'>Customer:</Box>
            <Box ml='2'>{customerName}</Box>
            <Box ml='4' textStyle='normalText'>{customerPhone}</Box>
          </Flex>
        </Box>
        <Box my='2'>
          <Flex>
            <Box textStyle='sectionTitle'>Items</Box>
            <Spacer />
            <Box textStyle='normalTextBlue'>${calTotal(cart)} / {itemNoText(cart)}</Box>
          </Flex>
          <div>
            {cart && cart.map((item, idx) =>
              <Item key={idx} item={item} />)}
          </div>
        </Box>
      </Flex>
    </Container>
  );
};

export default Success;