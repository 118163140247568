import { Center, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useState } from 'react';

import { calTotal } from '../states/cartActions';
import { constructOrder, orderAdd } from '../states/ordersActions';
import { useCreateOnlineCheckout } from '../states/useRemoteStates';
import { useStore } from '../states/useStore';

const warningToast = toast => message => {
  toast({
    title: message,
    status: 'warning',
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  });
};

const ChkoutButton = () => {
  const toast = useToast();
  const warning = warningToast(toast);

  const [submitting, setSubmitting] = useState(false);

  const orderCreate = useCreateOnlineCheckout();
  const cart = useStore(state => state.cart);

  const handleChkout = e => {
    e.preventDefault();

    if (submitting) {
      console.log('submitting...');
      return;
    }

    setSubmitting(true);
    toast.closeAll();

    // preliminary validate and construct order data
    const order = constructOrder();
    if (order instanceof Error) {
      setSubmitting(false);
      warning(order.message);
      return;
    }
    // post order to server
    orderCreate.mutate(order, {
      onSuccess: ({ data }) => {
        if (data.squareCheckoutId && data.squareCheckoutUrl) {
          // save the order returning from server
          orderAdd(data);
          // redirect to Square Checkout page
          // navigate(data.squareCheckoutUrl);
          window.location.href = data.squareCheckoutUrl;
        } else {
          warning('Server not responded. Please try again later!');
        }
      },
      onError: console.log, // to be send error message
      onSettled: () => {
        // delay enable checkout button
        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
      }
    });
  };

  return (
    submitting ?
      <Text color='cyan'>Checking... Please wait</Text>
      :
      <Center w='full' cursor='pointer' onClick={handleChkout}>
        Checkout ${calTotal(cart)}
      </Center>
  );

};

export default ChkoutButton;